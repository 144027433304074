import React from "react"
import { graphql } from "gatsby"
import Header from "../../components/Layout/header"
import Footer from "../../components/Layout/footer"
import SEO from "../../components/Global/MetaHead"

const PubOfertaRu = ({ data }) => {
  const pageSlug = "publichna-oferta"
  return (
    <>
      <SEO
        lang="ru"
        title="Публичная оферта Центра Лицензий Украины"
        description="Предметом настоящего договора являются отношения между веб-ресурсом и пользователем, который пользуется сервисами веб-ресурса."
        image={`https:${data.ogImage.fixed.src}`}
        url={`/ru/${pageSlug}/`}
        noindex="noindex"
      />
      <Header lang="ru" />
      <section className="publichna-oferta container py-5">
        <h1 className="text-center">Договор оферты</h1>
        <ol className="my-5">
          <li>
            Предметом настоящего договора являются отношения между сайтом
            лицензии.укр и пользователем, который пользуется сервисами сайта.
          </li>
          <li>
            Пользователем признается любое физическое лицо, осуществляющее
            доступ к Сайта с помощью сети Интернет. Настоящее Соглашение
            становится действительной с пользователем при входе на сайт.
            Дальнейшее использование Сайта является подтверждением согласия
            Пользователя с условиями настоящего Соглашения. Использование
            Пользователем Сайта, любых его услуг, означает безоговорочное
            согласие пользователя со всеми пунктами настоящего Соглашения и
            безоговорочное принятие его условий.
          </li>
          <li>
            Администратор оставляет за собой право по своему усмотрению изменять
            Соглашение в любое время без специального предварительного и (или)
            последующего сообщение пользователя. Продолжение использования
            Пользователем Сайта после любых изменений Соглашения предусматривает
            согласие Пользователя с такими изменениями.
          </li>
          <li>
            Администратор предоставляет Пользователю доступ к Сайту с основной
            целью предоставления Пользователю доступа к модулям электронного
            управления (далее - Контент). Доступ к Контента на Сайте
            предоставляется Пользователям исключительно для некоммерческого
            использования.
          </li>
          <li>
            Пользователь подтверждает, что достиг 18-летнего возраста.
            Пользователь, который не достиг необходимого возраста, обязуется
            воздержаться от пользования веб-ресурсом.
          </li>
          <li>
            Пользователь гарантирует, что при доступе к Контента не осуществляет
            и не будет осуществлять каких-либо действий, направленных на обход
            технических средств защиты.
          </li>
          <li>
            Доступ к Сайту и Контенту предоставляется "в том виде, как есть", и
            Администратор не гарантирует соответствие предоставляемых услуг
            ожиданиям Пользователя.
          </li>
          <li>
            Пользователь понимает и соглашается с тем, что пользователь может на
            своё усмотрению и без специального уведомления Пользователя,
            изменять набор Контента и / или функционал сайта.
          </li>
          <li>
            Пользователь понимает и соглашается с тем, что Администратор не
            гарантирует безошибочную и бесперебойную работу сайта и по умолчанию
            не несет ответственности за вред, причиненный Пользователю
            техническими сбоями аппаратного или программного обеспечения на
            любой стороне.
          </li>
          <li>
            Пользователь соглашается с использованием Администратором
            персональных данных Пользователя с целью обеспечения работы сайта,
            предоставления Пользователю услуг доступа к Контенту, а также
            информационного обслуживания Пользователя. Пользователь соглашается
            на получение сообщений от Администратора на указанную Пользователем
            при регистрации адрес электронной почты. Администратор обязуется не
            раскрывать информацию о пользователе третьим лицам без согласия
            пользователя, за исключением раскрытия информации по требованию суда
            и / или правоохранительных органов в соответствии с действующего
            законодательства.
          </li>
          <li>
            Администратор оставляет за собой право в любое время по своему
            усмотрению приостанавливать, ограничивать или прекращать доступ
            Пользователя к Сайту или к отдельных услуг Сайта, в том числе, но не
            исключительно, в случае нарушения Пользователем настоящего
            Соглашения и / или действующего законодательства.
          </li>
          <li>
            Администрация Сайта, ООО "Центр лицензий" не несет ответственности
            за возможный вред причиненный третьим лицам действиями Пользователей
            сайта или контентом, который розмещаеться на Сайте Пользователем.
          </li>
        </ol>
        <h2>Согласие на сбор и обработку персональных данных</h2>
        <ol className="my-5">
          <li>
            Путем проставления отметки на странице или при оформлении заказа на
            интернет-сайте лицензии.укр в пункте «Согласие с правилами сайта»
            Пользователь - физическое лицо, субъект персональных данных,
            предоставляемых ООО «ЦЕНТР ЛИЦЕНЗИЙ» свое безусловное согласие на
            сбор и обработку персональных данных.
          </li>
          <li>
            Я, как Пользователь интернет-сайта лицензии.укр, даю согласие ООО
            «ЦЕНТР ЛИЦЕНЗИЙ» на обработку, использование и хранение моих
            персональных данных в картотеках и / или с помощью базы персональных
            данных, владельцем которой является ООО «ЦЕНТР ЛИЦЕНЗИЙ» (далее -
            Владелец) и которая находится по адресу: 03164, г.Киев, ул.
            Подлесная, 1, оф. 8.
          </li>
          <li>
            Путем проставления отметки на странице или при оформлении заказа на
            интернет-сайте лицензии.укр в пункте «Согласие с правилами сайта»,
            подтверждаю, что уведомлен (-а) и согласен (-а) с последующим:
          </li>
          <ul>
            <li>
              Владелец осуществляет обработку персональных данных пользователя
              любыми способами с целью надлежащего предоставления Пользователю
              услуг (идентификация, аутентификация, авторизация, обезличивание,
              восстановление пароля, отправка информационных материалов по
              соглашению пользователя, ответов на запросы и письма пользователя,
              получение заявок на приобретение товара, оставление отзывов о
              качестве обслуживания Владельца, получение рекламных и специальных
              предложений, информации об акциях, розыгрыши, другую информацию о
              деятельности Владельца с целью проведения успешных и безопасных
              транзакций заказов и оплат; осуществления предлагаемых услуг;
              проведения маркетинговых акций и вручения призовых вознаграждений)
            </li>
            <li>
              При использовании интернет-сайтом Владелец осуществляет сбор и
              обработку персональных данных пользователя, связанных с
              идентификацией, аутентификацией, авторизацией, обезличиванием,
              восстановлением пароля, отправкой информационных материалов с
              согласия пользователя, ответами на запросы и письма пользователя,
              получением заявок на приобретение товара, оставлением отзывов о
              качестве обслуживания Владельца, для регистрации в программе
              скидок Владельца, получение рекламных и специальных предложений,
              информации об акциях, розыгрыши, другой информации о деятельность
              Владельца, осуществление предлагаемых услуг; проведения
              маркетинговых акций и вручения призовых вознаграждений, среди
              которых могут быть следующие персональные данные:
            </li>
            <ul>
              <li>
                фамилия, имя, отчество, адрес доставки заказов, контактный номер
                телефона, e-mail адрес, данные по заказам, бонусы-скидки, другие
                сведения, содержащие персональные данные в электронной форме;
              </li>
              <li>
                персональные данные, предоставленные Пользователем при
                заполнении регистрационных форм на интернет-сайте, а так же в
                процессе пользования интернет-сайтом;
              </li>
              <li>
                Файлы cookie (куки-файлы). Куки-файлы являются информационными
                файлами, которые поступают к компьютеру или мобильному
                устройству, когда тот используется для посещения сайты ООО
                «ЦЕНТР ЛИЦЕНЗИЙ» не предоставляет возможности третьим сторонам
                помещать куки-файлы на устройство. С помощью куки-файлов ООО
                «ЦЕНТР ЛИЦЕНЗИЙ» может собирать информацию о вашем пользования
                нашим сайтом, ваш IP-адрес, тип браузера и операционной системы.
                Также с целью обработки ваших покупок, осуществленных в режиме
                онлайн, мы можем собирать вашу платежную информацию, информацию
                о ваших предпочтениях в отношении продукции и тому подобное.
              </li>
            </ul>
            <li>
              Владелец включая данные пользователя в базу персональных данных
              «Физические лица, которые принимают участие в хозяйственной
              деятельности ООО «ЦЕНТР ЛИЦЕНЗИЙ» с момента, когда Пользователь
              впервые предоставляет (направляет) свои персональные данные
              Владельцу путем заполнения форм на интернет-странице Владельца.
            </li>
            <li>
              Нажимая на кнопку «Отправить» или «Отправить», я добровольно
              согласен предоставить указанные выше персональные данные о себе
              для общего доступа неограниченному кругу лиц в рамках
              использования Базы персональных данных «Физические лица, которые
              принимают участие в хозяйственной деятельности ООО «ЦЕНТР
              ЛИЦЕНЗИЙ».
            </li>
            <li>
              Владелец использует обезличенные данные для таргетинга рекламных и
              / или информационных материалов по возрасту, полу, другим данным;
              для проведения статистических исследований; другими способами.
            </li>
            <li>
              Владелец базы данных не информирует субъекта персональных данных о
              передаче его персональных данных в случаях, предусмотренных
              Законом Украины «О защите персональных данных».
            </li>
          </ul>
          <li>
            Пользователь имеет все права по защите его персональных данных,
            предусмотренных действующим законодательством Украины, в частности,
            Законом Украины «О защите персональных данных », а именно:
          </li>
          <ul>
            <li>
              знать об источниках сбора, местонахождение своих персональных
              данных, цель их обработки, местонахождение или место жительства
              (пребывания) владельца или распорядителя персональных данных или
              дать соответствующее поручение о получении этой информации
              уполномоченным им лицам, кроме случаев, установленных законом;
            </li>
            <li>
              получать информацию об условиях предоставления доступа к
              персональным данным, в частности информацию о третьих лицах,
              которым передаются его персональные данные;
            </li>
            <li>на доступ к своим персональным данным;</li>
            <li>
              получать не позднее чем за тридцать календарных дней со дня
              поступления запроса, кроме случаев, предусмотренных законом, ответ
              о том, обрабатываются его персональные данные, а также получать
              содержание таких персональных данных;
            </li>
            <li>
              предъявлять мотивированное требование владельцу персональных
              данных с возражением против обработки своих персональных данных;
            </li>
            <li>
              предъявлять мотивированное требование об изменении или уничтожение
              своих персональных данных любым владельцем и распорядителем
              персональных данных, если эти данные обрабатываются незаконно или
              являются недостоверными;
            </li>
            <li>
              на защиту своих персональных данных от незаконной обработки и
              случайной утраты, уничтожения, повреждения в связи с умышленным
              сокрытием, непредоставлением или несвоевременным их
              предоставлением, а также на защиту от предоставления сведений,
              которые являются недостоверными или порочащих честь, достоинство и
              деловую репутацию физического лица;
            </li>
            <li>
              обращаться с жалобами на обработку своих персональных данных в
              Уполномоченного или в суд;
            </li>
            <li>
              применять средства правовой защиты в случае нарушения
              законодательства о защите персональных данных;
            </li>
            <li>
              вносить оговорки об ограничении права на обработку своих
              персональных данных при предоставлении согласия;
            </li>
            <li>отозвать согласие на обработку персональных данных;</li>
            <li>
              знать механизм автоматической обработки персональных данных;
            </li>
            <li>
              на защиту от автоматизированного решения, которое имеет для него
              правовые последствия.
            </li>
          </ul>
          <li>
            Владелец принимает все предусмотренные законодательством меры для
            защиты персональных данных пользователя, в частности, обработка
            данных осуществляется на оборудовании, которое находится в
            охраняемых помещениях с ограниченным доступом.
          </li>
          <li>
            Путем проставления отметки на странице регистрации или при
            оформлении заказ на интернет-сайте ООО «ЦЕНТР ЛИЦЕНЗИЙ» в нункти
            «Согласие с правилами сайта», я также подтверждаю, что уведомлен
            (-а) о включении информации обо мне в базу персональных данных с
            вышеуказанной целью, о мои права, определенные Законом Украины «О
            защите персональных данных», а также о лицах, которым мои данные
            передаются для выполнения вышеупомянутой цели.
          </li>
          <li>
            Сайт лицензии.укр может содержать ссылки на сайты, которые не
            относятся к ООО «ЦЕНТР ЛИЦЕНЗИЙ». Такие гиперссылки приведены
            исключительно для информационных целей. При использовании таких
            сайтов мы рекомендуем Вам внимательно прочитать их политику
            конфиденциальности.
          </li>
          <li>
            Указанное соглашение предоставлена с учетом требований ст.7, ст.8 и
            ст.11 Закона Украины «О защите персональных данных» и действует без
            ограничения территории действия.
          </li>
          <li>
            Я этим подтверждаю, что получил сообщение о Владельца персональных
            данных, состав и содержание собранных персональных данных, а также
            сведения о мои права, определенных законом Украины «О защите
            персональных данных» для выполнения указанной цели и возможности
            передачи моих персональных данных третьим лицам по усмотрению
            Владельца и / или распорядителя базы данных.
          </li>
          <li>
            Содержание моих прав как субъекта персональных данных в соответствии
            с Законом Украины «О защите персональных данных» мне сообщено и
            понятно.
          </li>
          <li>
            Указанное соглашение действует бессрочно до даты его отзыва мною
            путем направленные в адрес ООО «ЦЕНТР ЛИЦЕНЗИЙ» письменного
            уведомления о указанное отзывы в произвольной форме, если иное не
            установлено действующим законодательством Украины.
          </li>
          <li>
            ООО «ЦЕНТР ЛИЦЕНЗИЙ» оставляет за собой право в любое время внести
            изменения к данному положению. После внесения изменений, такие
            изменения вступят в силу сразу же после размещения их на официальном
            сайте ООО «ЦЕНТР ЛИЦЕНЗИЙ».
          </li>
        </ol>
      </section>
      <Footer slug={`/${pageSlug}/`} lang="ru" />
    </>
  )
}

export const query = graphql`
  {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
  }
`

export default PubOfertaRu
